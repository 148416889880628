import { Button } from "@/components/ui/button";
import { motion, useAnimate } from "framer-motion";
import { useEffect, useState } from "react";
import { StillCurious } from "./still-curious";
import { InDemandBanner } from "@/components/in-demand-banner";
import { useNavigate, useLocation } from "react-router-dom";
import { CustomInput } from "@/components/custom-input";
import actions from "@/store/actions";

interface HeroProps {
  mainColor?: string;
}

const StarSvg = () => (
  <svg
    className="svg-icon svg-icon--star w-[13px] h-[13px] text-[#FF553C]"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.80729 0.655781C9.19147 -0.218594 9.8084 -0.218594 10.193 0.655781L12.6447 6.22902L18.1772 7.06861C19.0648 7.20264 19.2653 7.86154 18.6265 8.54377L14.588 12.8227L15.5787 18.9209C15.7303 19.8773 15.216 20.2677 14.4315 19.8067L9.50003 16.8973L4.56876 19.8067C3.78387 20.2677 3.26973 19.8773 3.42116 18.9209L4.41182 12.8227L0.373554 8.54377C-0.265227 7.86154 -0.0649666 7.20264 0.822905 7.06861L6.35495 6.22902L8.80729 0.655781Z"
      fill="currentColor"
    />
  </svg>
);

const FeatureCardSvgOne = () => (
  <svg
    width="139"
    height="150"
    viewBox="0 0 139 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M70.3713 111.084C79.8942 112.647 89.7076 110.379 96.9816 104.935L133.688 77.4615C146.754 67.6826 148.028 50.6584 136.533 39.4369C125.038 28.2155 105.128 27.0462 92.0627 36.8252L86.3432 43.541L80.7701 33.7561C72.7466 20.5159 53.7239 15.2956 38.2817 22.0961C22.8395 28.8966 16.8254 45.1428 24.8489 58.383L48.2082 96.9299C52.675 104.301 60.8485 109.521 70.3713 111.084Z"
      fill="#FF553C"
    ></path>
    <path
      d="M83.1912 105.613C76.8681 98.6553 72.7955 96.4066 74.877 88.0806L84.7429 48.7371C88.4817 33.7817 105.579 25.0337 122.931 29.1979C140.282 33.3622 151.318 48.8617 147.579 63.8171L146.36 68.5728L134.683 117.697L110.777 108.963C100.9 108.711 89.5144 112.571 83.1912 105.613Z"
      fill="url(#paint0_linear_36_971)"
    ></path>
    <path
      d="M83.1912 105.613C76.8681 98.6553 72.7955 96.4066 74.877 88.0806L84.7429 48.7371C88.4817 33.7817 105.579 25.0337 122.931 29.1979C140.282 33.3622 151.318 48.8617 147.579 63.8171L146.36 68.5728L134.683 117.697L110.777 108.963C100.9 108.711 89.5144 112.571 83.1912 105.613Z"
      fill="#FFB6AB"
    ></path>
    <path
      d="M67.4338 111.241C57.7466 109.258 49.6394 103.597 45.4502 95.8896L24.3105 56.9969C16.7859 43.1532 23.8249 26.8114 40.0326 20.4965C56.2403 14.1816 75.4791 20.2849 83.0037 34.1286L86.7411 41.0045L115.341 92.2859L95.0654 106.046C87.3111 111.308 77.1209 113.224 67.4338 111.241Z"
      fill="url(#paint1_linear_36_971)"
    ></path>
    <path
      d="M67.4338 111.241C57.7466 109.258 49.6394 103.597 45.4502 95.8896L24.3105 56.9969C16.7859 43.1532 23.8249 26.8114 40.0326 20.4965C56.2403 14.1816 75.4791 20.2849 83.0037 34.1286L86.7411 41.0045L115.341 92.2859L95.0654 106.046C87.3111 111.308 77.1209 113.224 67.4338 111.241Z"
      fill="#FFB6AB"
    ></path>
    <path
      d="M130.935 121.921C140.464 123.483 150.294 121.168 157.591 115.646L194.412 87.7781C207.518 77.8586 208.839 60.6336 197.363 49.3051C185.887 37.9765 165.959 36.8342 152.853 46.7537L146.343 51.6806L97.1482 88.1738L108.787 107.648C113.239 115.096 121.407 120.359 130.935 121.921Z"
      fill="url(#paint2_linear_36_971)"
    ></path>
    <path
      d="M130.935 121.921C140.464 123.483 150.294 121.168 157.591 115.646L194.412 87.7781C207.518 77.8586 208.839 60.6336 197.363 49.3051C185.887 37.9765 165.959 36.8342 152.853 46.7537L146.343 51.6806L97.1482 88.1738L108.787 107.648C113.239 115.096 121.407 120.359 130.935 121.921Z"
      fill="#FFB6AB"
    ></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M120.425 59.3374C117.244 56.8106 112.768 55.9037 108.619 56.9461L70.8218 66.4437C64.2719 68.0895 60.5057 74.0133 62.4098 79.6748C64.3139 85.3362 71.1672 88.5916 77.7171 86.9457L97.2736 82.0317L84.5717 133.506C84.4364 134.054 84.4412 134.611 84.5712 135.163C84.5362 139.207 88.4155 143.001 93.959 144.023C100.166 145.168 106.108 142.403 107.232 137.849L107.243 137.803C107.254 137.766 107.264 137.729 107.273 137.691L119.611 87.6906L130.697 96.4983C135.719 100.488 143.532 100.204 148.148 95.8629C152.764 91.5221 152.435 84.7688 147.413 80.779L120.425 59.3374Z"
      fill="url(#paint3_linear_36_971)"
    ></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M120.425 59.3374C117.244 56.8106 112.768 55.9037 108.619 56.9461L70.8218 66.4437C64.2719 68.0895 60.5057 74.0133 62.4098 79.6748C64.3139 85.3362 71.1672 88.5916 77.7171 86.9457L97.2736 82.0317L84.5717 133.506C84.4364 134.054 84.4412 134.611 84.5712 135.163C84.5362 139.207 88.4155 143.001 93.959 144.023C100.166 145.168 106.108 142.403 107.232 137.849L107.243 137.803C107.254 137.766 107.264 137.729 107.273 137.691L119.611 87.6906L130.697 96.4983C135.719 100.488 143.532 100.204 148.148 95.8629C152.764 91.5221 152.435 84.7688 147.413 80.779L120.425 59.3374Z"
      fill="white"
    ></path>
    <path
      d="M111.997 37.5965C112.672 40.6908 116.521 41.0695 118.268 39.086M121.481 39.6783C122.157 42.7726 126.005 43.1514 127.753 41.1678M105.105 38.6214C109.733 46.7687 127.226 50.0307 133.971 44.3136"
      stroke="#FB573C"
      stroke-width="1.42166"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M40.7813 37.1762C44.1159 39.8938 48.3571 37.8191 48.4386 34.6832M52.2154 33.2323C55.5501 35.9499 59.7912 33.8752 59.8727 30.7394M37.4761 42.634C49.1576 47.9672 64.3899 43.1565 66.3641 33.026"
      stroke="#FB573C"
      stroke-width="1.78854"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <defs>
      <linearGradient
        id="paint0_linear_36_971"
        x1="123.423"
        y1="26.5003"
        x2="102.2"
        y2="114.147"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFA3A3"></stop>
        <stop offset="1" stop-color="#FDDC5D"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear_36_971"
        x1="38.6345"
        y1="18.4394"
        x2="76.7265"
        y2="108.311"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFA3A3"></stop>
        <stop offset="1" stop-color="#FDDC5D"></stop>
      </linearGradient>
      <linearGradient
        id="paint2_linear_36_971"
        x1="199.56"
        y1="47.9281"
        x2="125.782"
        y2="117.892"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFA3A3"></stop>
        <stop offset="1" stop-color="#FDDC5D"></stop>
      </linearGradient>
      <linearGradient
        id="paint3_linear_36_971"
        x1="122.956"
        y1="170.216"
        x2="63.7014"
        y2="91.5129"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white"></stop>
        <stop offset="1" stop-color="#FFE8F6"></stop>
      </linearGradient>
    </defs>
  </svg>
);
const FeatureCardSvgTwo = () => (
  <svg
    width="168"
    height="165"
    viewBox="0 0 168 165"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M116.451 126.972C124.574 120.375 129.212 110.409 129.026 99.9458L128.088 47.1471C127.754 28.3535 112.248 13.3891 93.4542 13.723C74.6606 14.057 59.6962 29.563 60.0301 48.3565L60.196 57.6909L60.7438 127.637L87.4186 133.73C97.6203 136.06 108.327 133.568 116.451 126.972Z"
      fill="url(#paint0_linear_36_989)"
    ></path>
    <path
      d="M116.451 126.972C124.574 120.375 129.212 110.409 129.026 99.9458L128.088 47.1471C127.754 28.3535 112.248 13.3891 93.4542 13.723C74.6606 14.057 59.6962 29.563 60.0301 48.3565L60.196 57.6909L60.7438 127.637L87.4186 133.73C97.6203 136.06 108.327 133.568 116.451 126.972Z"
      fill="#D5B5FF"
    ></path>
    <path
      d="M83.6981 31.897C85.8877 36.3309 91.0796 35.499 92.5268 31.8607M96.9776 31.584C99.1672 36.0179 104.359 35.1859 105.806 31.5477M81.3871 40.4934C90.8794 51.1275 101.498 48.8117 107.903 37.7018"
      stroke="#6635A6"
      stroke-width="1.85102"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <rect
      x="141.999"
      y="31.8115"
      width="19.9449"
      height="54.9314"
      rx="9.97243"
      transform="rotate(39.4683 141.999 31.8115)"
      fill="#D5B5FF"
    ></rect>
    <rect
      width="19.9449"
      height="54.9314"
      rx="9.97243"
      transform="matrix(-0.771976 0.635651 0.635651 0.771976 44.9761 31.8115)"
      fill="#D5B5FF"
    ></rect>
  </svg>
);

const FeatureCardSvgThree = () => (
  <svg
    width="183"
    height="198"
    viewBox="0 0 183 198"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M121.632 8.47342C121.632 7.20394 122.627 6.17374 123.851 6.17374C125.078 6.17374 126.07 7.20393 126.07 8.47379C126.07 9.74327 125.077 10.7735 123.851 10.7735C123.717 10.7735 123.585 10.7593 123.458 10.7352L116.669 32.0172C105.276 28.7114 99.3442 29.0452 89.2248 32.0168L82.4357 10.7348C82.3077 10.7586 82.1769 10.7731 82.0421 10.7731C80.8173 10.7731 79.8234 9.74328 79.8234 8.47342C79.8234 7.20394 80.8174 6.17374 82.0429 6.17374C83.2677 6.17374 84.2616 7.20356 84.2616 8.47342C84.2616 9.39557 83.7361 10.1883 82.9809 10.5554C85.2176 13.0129 90.8895 18.9211 93.7168 18.9211C98.702 18.9211 101.478 9.09237 102.495 4.55142C101.486 4.33476 100.727 3.409 100.727 2.29967C100.727 1.03019 101.722 0 102.947 0C104.173 0 105.166 1.02981 105.166 2.29967C105.166 3.40938 104.408 4.3352 103.399 4.55142C104.415 9.09275 107.192 18.9211 112.176 18.9211C115.004 18.9211 120.677 13.0129 122.913 10.5554C122.157 10.1883 121.632 9.3955 121.632 8.47342Z"
      fill="#FFCE54"
    ></path>
    <path
      d="M126.782 156.023C135.626 148.842 140.675 137.991 140.472 126.6L139.451 69.1171C139.087 48.6562 122.206 32.3642 101.745 32.7278C81.2842 33.0915 64.9921 49.973 65.3558 70.4338L65.5364 80.5963L66.1327 156.748L95.174 163.381C106.281 165.918 117.937 163.205 126.782 156.023Z"
      fill="url(#paint0_linear_441_3801)"
    ></path>
    <path
      d="M126.782 156.023C135.626 148.842 140.675 137.991 140.472 126.6L139.451 69.1171C139.087 48.6562 122.206 32.3642 101.745 32.7278C81.2842 33.0915 64.9921 49.973 65.3558 70.4338L65.5364 80.5963L66.1327 156.748L95.174 163.381C106.281 165.918 117.937 163.205 126.782 156.023Z"
      fill="#FFCE54"
    ></path>
    <path
      d="M91.1235 52.5144C93.5073 57.3417 99.1599 56.4359 100.735 52.4749M105.581 52.1736C107.965 57.0009 113.618 56.0951 115.193 52.1342M88.6075 61.8735C98.9419 73.451 110.502 70.9298 117.476 58.8342"
      stroke="#97700F"
      stroke-width="2.01524"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
);

function StarRating({ mainColor = "rgb(33, 150, 243)" }: HeroProps) {
  const [scope, animate] = useAnimate();
  const location = useLocation();
  const isFreePage = location.pathname.includes("free");

  useEffect(() => {
    const animateStars = async () => {
      // Check if scope.current exists and has child elements
      if (!scope.current) return;

      while (true) {
        try {
          for (let i = 0; i < 5; i++) {
            // Add null check before animation
            const element = scope.current?.querySelector(`div:nth-child(${i + 1})`);
            if (element) {
              await animate(
                element,
                { scale: [1, 0.4, 1.2, 1] },
                { duration: 0.4, ease: "easeInOut" }
              );
            }
          }
          await new Promise((resolve) => setTimeout(resolve, 2000));
        } catch (error) {
          console.error('Animation error:', error);
          break; // Break the loop if there's an error
        }
      }
    };

    // Start animation after a short delay to ensure DOM is ready
    const timeoutId = setTimeout(() => {
      animateStars();
    }, 100);

    // Cleanup function
    return () => {
      clearTimeout(timeoutId);
    };
  }, [animate, scope]);

  const getReviewText = () => {
    if (location.pathname.includes("free")) {
      return {
        count: "10,000+",
        text: "Happy Customers",
      };
    }
    return {
      count: "1000+",
      text: "Verified Customer Reviews",
    };
  };

  const reviewInfo = getReviewText();

  return (
    <div className="flex flex-col items-center space-y-0">
      <div className="md:hidden block">
        <InDemandBanner />
      </div>
      <div className="flex items-center gap-1">
        <div ref={scope} className="flex gap-[2px]">
          {[...Array(5)].map((_, i) => (
            <motion.div key={i}>
              <StarSvg />
            </motion.div>
          ))}
        </div>
        <span className="ml-2 text-sm text-[#4A4A4A] font-medium">
          {reviewInfo.count}
        </span>
        <a
          href="#client-stories"
          className="underline text-sm text-[#4A4A4A] hover:text-[#2D2D2D] font-medium"
        >
          {reviewInfo.text}
        </a>
      </div>
      {location.pathname.includes("free") ? (
        <p className="text-[#4A4A4A] text-sm pt-1">
          <span className="font-semibold">100% Free</span> - No Credit Card
          Required
        </p>
      ) : (
        <p className="text-[#4A4A4A] text-sm pt-1">
          <span className="font-semibold">1.5 million</span> individual users
          and counting
        </p>
      )}
    </div>
  );
}

function TrustLogos() {
  return (
    <div className="flex justify-center items-center gap-8 mt-12 grayscale opacity-70">
      <img src="/logos/us.svg" alt="US Magazine" className="h-6" />
      <img src="/logos/mens-journal.svg" alt="Men's Journal" className="h-6" />
      <img src="/logos/usweekly.svg" alt="US Weekly" className="h-6" />
      <img
        src="/logos/digital-trends.svg"
        alt="Digital Trends"
        className="h-6"
      />
    </div>
  );
}

interface FeatureCardProps {
  title: string;
  description: string;
  mainColor?: string;
  id: string;
  isExpanded: boolean;
  onToggle: () => void;
  svgRight?: string;
  svgBottom?: string;
}

function FeatureCard({
  title,
  description,
  mainColor = "#FF553C",
  id,
  isExpanded,
  onToggle,
  svgRight = "right-14",
  svgBottom = "-bottom-5",
}: FeatureCardProps) {
  const [currentSvg, setCurrentSvg] = useState<React.ReactNode | null>(null);

  useEffect(() => {
    switch (id) {
      case "share":
        setCurrentSvg(FeatureCardSvgOne);
        break;
      case "tell":
        setCurrentSvg(FeatureCardSvgTwo);
        break;
      case "get":
        setCurrentSvg(FeatureCardSvgThree);
        break;
    }
  }, [id]);

  return (
    <div
      onClick={onToggle}
      className="bg-white rounded-[32px] p-6 md:p-8 relative cursor-pointer shadow-[0_10px_50px_-12px_rgba(0,0,0,0.12)] hover:shadow-[0_25px_80px_-12px_rgba(0,0,0,0.25)] transition-all duration-300"
    >
      <div className="absolute inset-0 overflow-hidden rounded-[32px]">
        <div className={`absolute ${svgRight} ${svgBottom}`}>
          <div className="w-full">
            <div className="h-full opacity-80 [mask-image:linear-gradient(to_top,transparent,black_80%)] [-webkit-mask-image:linear-gradient(to_top,transparent,black_80%)]">
              {currentSvg}
            </div>
          </div>
        </div>
      </div>

      <div className="relative z-10">
        <h3 className="text-[1.3rem] font-semibold text-[#2D2D2D] leading-tight mb-4">
          {title}
        </h3>
        <div className="relative">
          <div
            className={`text-[#4A4A4A] text-lg pr-20 transition-all duration-500 ease-in-out overflow-hidden`}
            style={{
              maxHeight: isExpanded ? "500px" : "120px",
            }}
          >
            <div
              className="relative !text-body-sm-r leading-[1.6]"
              style={{
                WebkitMaskImage: !isExpanded
                  ? "linear-gradient(to bottom, black 50%, transparent 95%)"
                  : "none",
                maskImage: !isExpanded
                  ? "linear-gradient(to bottom, black 50%, transparent 95%)"
                  : "none",
              }}
            >
              {description}
              <motion.div
                initial={false}
                animate={{
                  opacity: isExpanded ? 1 : 0,
                  height: isExpanded ? "auto" : 0,
                }}
                transition={{
                  duration: 0.5,
                  ease: "easeInOut",
                }}
                className="overflow-hidden"
              >
                <p className="mt-4">
                  Our platform ensures your TikTok growth is organic and
                  sustainable. We use advanced targeting to connect you with
                  real TikTok users who are genuinely interested in your
                  content. This means better engagement rates, increased
                  visibility, and a stronger presence in the TikTok community.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
        <button
          onClick={(e) => {
            e.stopPropagation();
            onToggle();
          }}
          className="mt-4 font-bold flex items-center gap-1 group"
          style={{ color: mainColor }}
        >
          <motion.svg
            width="14"
            height="14"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {!isExpanded ? (
              <path
                d="M12 4L12 20M4 12L20 12"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            ) : (
              <path
                d="M4 12L20 12"
                stroke="currentColor"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            )}
          </motion.svg>
          {isExpanded ? "Less" : "More"}
        </button>
      </div>
    </div>
  );
}

function Features({ mainColor = "#FF553C" }) {
  const [expandedCards, setExpandedCards] = useState<{
    [key: string]: boolean;
  }>({
    share: false,
    tell: false,
    get: false,
  });

  const toggleCard = (id: string) => {
    setExpandedCards((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <div className="container mx-auto px-4 mt-16 max-w-6xl">
      <div className="relative md:rounded-[48px]">
        <div
          className="absolute left-1/2 top-0 md:hidden"
          style={{
            backgroundColor: mainColor,
            filter: "brightness(0.85)",
            width: "110%",
            height: "100%",
            transform: "translate(-50%, -9%)",
            borderRadius: "12.9rem",
          }}
        />
        <div
          className="absolute inset-0 hidden md:block rounded-[48px]"
          style={{
            backgroundColor: mainColor,
            filter: "brightness(0.85)",
          }}
        />
        <div className="relative z-10 py-6 md:py-12 px-8 md:px-8">
          <div className="grid md:grid-cols-3 gap-3 md:gap-3 items-start">
            <FeatureCard
              id="share"
              title="Share Your Content With More People"
              description="More TikTok followers, likes, and views help your content reach more people. Having an active TikTok page with high engagement increases your chances of appearing on the For You Page and trending sections."
              mainColor={mainColor}
              isExpanded={expandedCards.share}
              onToggle={() => toggleCard("share")}
              svgRight="-right-0"
              svgBottom="-bottom-3"
            />
            <FeatureCard
              id="tell"
              title="Tell Us What You Need"
              description="Want to reach more people who share your interests? See more engagement on your TikTok videos? Connect with a wider audience and boost your content's visibility on the platform?"
              mainColor={mainColor}
              isExpanded={expandedCards.tell}
              onToggle={() => toggleCard("tell")}
              svgRight="-right-9"
              svgBottom="-bottom-8"
            />
            <FeatureCard
              id="get"
              title="Get Highest Quality, Instant Views, Likes, and Follows"
              description="Many TikTok services use bots and fake accounts to give you worthless follows, likes, or views. We provide real engagement from active TikTok users to help your content go viral naturally."
              mainColor={mainColor}
              isExpanded={expandedCards.get}
              onToggle={() => toggleCard("get")}
              svgRight="-right-6"
              svgBottom="-bottom-12"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function Hero({ mainColor = "rgb(33, 150, 243)" }: HeroProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const getButtonText = () => {
    if (location.pathname.includes("get-free-likes")) {
      return "Get Your Free Likes";
    } else if (location.pathname.includes("get-free-views")) {
      return "Get Your Free Views";
    } else if (location.pathname.includes("get-free-shares")) {
      return "Get Your Free Shares";
    }
    return "Accelerate Your TikTok Growth";
  };

  const handleSubmit = async () => {
    const searchParams = new URLSearchParams(location.search);

    if (location.pathname.includes("free")) {
      setIsLoading(true);
      if (username.trim()) {
        try {
          const response = await actions.getAccountUsedPromotion(
            username.trim()
          );
          if (response.exists) {
            setError("You've already used your free promotion.");
            setIsLoading(false);
            return;
          }
          searchParams.set("username", username.trim());
          searchParams.set("from", location.pathname.replace("/", ""));
          setIsLoading(false);
          navigate(`/order?${searchParams.toString()}`);
        } catch (err) {
          setIsLoading(false);
          setError("Something went wrong. Please try again.");
        }
      } else {
        searchParams.set("from", location.pathname.replace("/", ""));
        setTimeout(() => {
          setIsLoading(false);
          navigate(`/order?${searchParams.toString()}`);
        }, 1000);
      }
    } else {
      searchParams.set("from", location.pathname.replace("/", ""));
      navigate(`/order?${searchParams.toString()}`);
    }
  };

  const handleSearch = (
    value: string | React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = typeof value === "string" ? value : value.target.value;
    setUsername(newValue);
    setError(null); // Clear error when user types
  };

  const handleClear = () => {
    setUsername("");
  };

  const getHeroContent = () => {
    if (location.pathname.includes("get-free-likes")) {
      return {
        title: "Free TikTok Likes - Instant Delivery",
        description:
          "Get 100% real TikTok likes from active users. No password required, instant delivery.",
      };
    } else if (location.pathname.includes("get-free-views")) {
      return {
        title: "Free TikTok Views - Instant Delivery",
        description:
          "Boost your TikTok views instantly with real viewers. No password needed, delivered instantly.",
      };
    } else if (location.pathname.includes("get-free-shares")) {
      return {
        title: "Free TikTok Shares - Instant Delivery",
        description:
          "Get authentic TikTok shares from real users. No password required, instant delivery.",
      };
    }
    return {
      title: "TikTok Growth Platform",
      description:
        "that helps you build your following with high-quality engagement.",
    };
  };

  const heroContent = getHeroContent();
  const isFreePage = location.pathname.includes("free");

  return (
    <>
      <div className="container mx-auto px-4 py-12 md:py-10 max-w-[1005px]">
        <div className="text-center space-y-4">
          <h1 className="md:text-[3.5rem] text-[31px] leading-[1.2] font-[600] text-[#2D2D2D] tracking-tight max-w-4xl mx-auto">
            {heroContent.title}
          </h1>

          {/* Description for desktop */}
          <div className="space-y-2 pb-4">
            <p className="text-[18px] leading-[1.1] text-[#4A4A4A]">
              {heroContent.description}
            </p>
          </div>

          {/* StarRating for mobile */}
          <div className="md:hidden pt-0">
            <StarRating mainColor={mainColor} />
          </div>

          <div className="flex flex-col items-center justify-center gap-4 pt-4">
            {isFreePage && (
              <div className="relative w-full max-w-[300px]">
                <CustomInput
                  placeholder="type your tiktok username"
                  value={username}
                  onChange={handleSearch}
                  onClear={handleClear}
                  loading={false}
                  error={!!error}
                  name="tiktok-username"
                  leftIcon={null}
                />
                {error && (
                  <p className="text-red-300 text-xs mt-1 text-left">{error}</p>
                )}
              </div>
            )}
            <Button
              onClick={handleSubmit}
              className="text-white px-12 md:px-16 py-7 !text-lg rounded-lg whitespace-nowrap min-w-[200px] md:min-w-[250px]"
              loading={isLoading}
            >
              {getButtonText()}
            </Button>
            {!isFreePage && (
              <div className="hidden md:block text-[#4A4A4A]">
                <span className="text-[0.8rem]"> Starting at </span>{" "}
                <span className="font-semibold text-sm">$1.47</span>
              </div>
            )}
          </div>

          {/* StarRating for desktop */}
          <div className="hidden md:block pt-0">
            <StarRating mainColor={mainColor} />
          </div>
        </div>
      </div>
      <Features mainColor={mainColor} />
    </>
  );
}
